import React, { useEffect, useRef } from "react";
// import d1 from "./Images/logo .png";
import d1 from "./Images/logo.gif";
function Refund() {
  const observedElements = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.transform = "scale(1)";
            entry.target.style.opacity = "1";
          } else {
            entry.target.style.transform = "scale(0.95)";
            entry.target.style.opacity = "0";
          }
        });
      },
      { threshold: 0.1 }
    );

    observedElements.current.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  return (
    <>
      <div className="Message">
        <div className="templatebrd">
          <h2>{"ABOUTUS > REFUND POLICY"}</h2>
          <h1>Refund Policy Information</h1> {/* Replace with dynamic or static text */}
        </div>
      </div>
      <div id="DirectorsMessage">
        <div className="onedirector">
          <img src={d1} alt="Director Image" />
          <div className="drinfo">
            <span>
              <h1>Refund Policy</h1>
            </span>
            <b>1. Eligibility for Refund</b>
            <p>
              At TASKAPRO Technologies India Private Limited, we strive to provide the highest quality services to our clients. However, we understand that situations may arise where a refund is necessary. Please review our refund policy below:
            </p>
            <p>
              Refunds may be considered under the following circumstances:
              <ul>
                <li>The service was not delivered as agreed upon.</li>
                <li>A request for cancellation was made before the service commenced.</li>
                <li>A technical error or double payment occurred.</li>
              </ul>
            </p>
            <b>2. Non-Refundable Services</b>
            <p>
              Refunds will not be provided in the following cases:
              <ul>
                <li>Services that have already been delivered or partially completed.</li>
                <li>Client dissatisfaction due to a change of mind or personal preference.</li>
                <li>Failure to provide necessary information required to complete the service.</li>
              </ul>
            </p>
            <b>3. Refund Request Process</b>
            <p>
              To request a refund, please contact us at <a href="mailto:admin@spanip.com">admin@spanip.com</a> with the following details:
              <ul>
                <li>Name and contact details</li>
                <li>Invoice number or payment receipt</li>
                <li>Reason for the refund request</li>
              </ul>
              Our team will review your request and respond within 30 business days.
            </p>
            <b>4. Processing Time</b>
            <p>
              Approved refunds will be processed within 7 business days and credited to the original payment method. Processing times may vary depending on your bank or payment provider.
            </p>
            <b>5. Changes to the Policy</b>
            <p>
              We reserve the right to modify this refund policy at any time. Any updates will be posted on this page.
            </p>
            <p>
              If you have any questions, please reach out to us at <a href="mailto:tap@spanip.com">tap@spanip.com</a>.
            </p>

            <p>
              Motivated by a relentless passion for consumer and patient safety, I was driven to establish our firm with a clear mission: to secure our clients' assets with unwavering integrity and dedication. We are specialists in addressing threats like Anti-Counterfeiting, Diversion, Product Tampering, and Intellectual Property Infringement. Our team, comprised of seasoned professionals, is dedicated to delivering service excellence, ensuring that we meet the specific needs of our clients with precision and care.
            </p>
            <p>
              The foundation of our firm is built on core values of honesty, humility, accountability, and nimble wit. These values guide our operations, ensuring we serve with the highest degree of integrity, transparency, and professionalism. Our ability to swiftly adapt to the dynamic market landscape allows us to offer tailored solutions that effectively address our clients’ unique challenges.
            </p>
          </div>
        </div>
      </div>
    </>
  );

}

export default Refund;