import React, { useEffect, useState, useCallback } from "react";
// import logo from "./Images/logo .png";
import logo from "./Images/logo.gif";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ServicesTraing from "./NavbarTraning";
import axios from "axios";

const dropdownData = [
  {
    label: "Span Suit",
    content: [
      {
        to: "/spansuit/systemprocessandtools",
        label: "Systems, Processes and Tools",
      },
      { to: "/spansuit/mobileappview", label: "Mobile APP" },
      { to: "/spansuit/brandprotection", label: "Brand Protection" },
    ],
  },
  {
    label: "Services",
    content: [
      { label: "Legal Brand Protection", handler: 1 },
      { label: "Strategic Consulting", handler: 2 },
      { label: "Investigations & Due Diligence", handler: 3 },
      { label: "Leadership & Development", handler: 4 },
      { label: "Services for the Legal Industry", handler: 5 },
    ],
  },
  {
    label: "Industries Served",
    content: [
      {
        to: "/industries/overviewofindustries",
        label: "Overview of Industries",
      },
      {
        to: "/industries/successtory",
        label: "Success Stories / Case Studies / Testimonials",
      },
    ],
  },
  {
    label: "Resources & Toolkits",
    content: [
      { to: "/resources/blogs", label: "Blog / Articles / Videos" },
      { to: "/resources/whitepaper", label: "Whitepapers" },
      { to: "/resources/casestudy", label: "Case Studies" },
      { to: "/resources/brandprotection", label: "Brand Protection Toolkits" },
    ],
  },
  {
    label: "About Us",
    content: [
      { to: "/aboutus/history", label: "History" },
      { to: "/about/directorsmessage", label: "Director's Message" },
      {
        to: "/aboutus/missionvisionvalues",
        label: "Mission, Vision and Values",
      },
      { to: "/aboutus/valuesandexpectations", label: "Value Creation" },
      { to: "/aboutus/fundingmodels", label: "Funding Model" },
      { to: "/aboutus/strategy", label: "Strategy" },
      { to: "/aboutus/capability", label: "Capability" },
      { to: "/aboutus/bestpractices", label: "Best Practices" },
      { to: "/aboutus/leadershipteam", label: "Leadership Team" },
      { to: "/aboutus/organisationstructure", label: "Organisation Structure" },
      {
        to: "/aboutus/rolesandresponsibility",
        label: "Roles and Responsibilities",
      },
      { to: "/aboutus/careers", label: "Careers" },
      { to: "/aboutus/governance", label: "Governance" },
      { to: "/aboutus/offices", label: "Offices" },
      { to: "/aboutus/refundPolicy", label: "Refund Policy" },
      { to: "/aboutus/termsandConditions", label: "Terms and Conditions" },
    ],
  },
  {
    label: "Contact Us",
    content: [
      { to: "/contactus", label: "Contact Form" },
      { to: "/inquiry", label: "Inquiry/Consultation Request" },
    ],
  },
  {
    label: "Partners",
    content: [
      {
        to: "/partnersandrepresentativeclients/partnership/partners",
        label: "Partners",
      },
      { label: "Representative Clients", handler: 6 },
    ],
  },
];

const serviceDropdownData = [
  {
    label: " ※ LEGAL BRAND PROTECTION",
    content: [
      { to: "/services/leagalbrandprotection/overview", label: " ※ Overview" },
      {
        to: "/services/leagalbrandprotection/ipregistration",
        label: " ※ IP Registration and Prosecution",
      },
      {
        to: "/services/leagalbrandprotection/ipaudits",
        label: " ※ IP Audits, SC Audits, EM Audit, Security Audits",
      },
      {
        to: "/services/leagalbrandprotection/intellectualproperties",
        label: " ※ Intellectual Property",
      },
      {
        to: "/services/leagalbrandprotection/confidentialinformation",
        label: " ※ Confidential Information and Trade Secrets",
      },
      {
        to: "/services/leagalbrandprotection/healthcareandregulations",
        label: " ※ Healthcare and Regulatory Laws",
      },
      {
        to: "/services/leagalbrandprotection/mediaentertainmentandsportslaws",
        label: " ※ Media, Entertainment & Sports Laws",
      },
      {
        to: "/services/leagalbrandprotection/digitalanditlaw",
        label: " ※ Digital & IT Laws",
      },
    ],
  },
  {
    label: " ※ STRATEGIC CONSULTING",
    content: [
      { to: "/services/stratagicconsulting/overview", label: " ※ Overview" },
      {
        to: "/services/stratagicconsulting/comprehensivelegalandbusinessriskconsultancy",
        label: " ※ Comprehensive Legal and Business Risk Consulting",
      },
      {
        to: "/services/stratagicconsulting/riskassesmentandmigrationplan",
        label: " ※ Risk Assessments and Mitigation Plans",
      },
      {
        to: "/services/stratagicconsulting/customizedconsultingservice",
        label: " ※ Customized Consulting Services",
      },
      {
        to: "/services/stratagicconsulting/chainsupplysecurityandintegratityprogram",
        label: " ※ Supply Chain Security/Integrity Programs",
      },
    ],
  },
  {
    label: " ※ INVESTIGATIONS & DUE DILIGENCE",
    content: [
      {
        to: "/services/investigationandduedilligence/counterfeit",
        label: " ※ Counterfeits",
      },
      {
        to: "/services/investigationandduedilligence/diversion",
        label: " ※ Diversion",
      },
      {
        to: "/services/investigationandduedilligence/producttampering",
        label: " ※ Product Tampering",
      },
      {
        to: "/services/investigationandduedilligence/leadgeneration",
        label: " ※ Lead Generation",
      },
      {
        to: "/services/investigationandduedilligence/counterpartyduediligence",
        label: " ※ Counter Party Due Diligence",
      },
      {
        to: "/services/investigationandduedilligence/seniormanagementduediligence",
        label: " ※ Senior Management Due Diligence",
      },
      {
        to: "/services/investigationandduedilligence/integrityduediligence",
        label: " ※ Integrity Due Diligence",
      },
      {
        to: "/services/investigationandduedilligence/facility",
        label: " ※ Facility",
      },
      {
        to: "/services/investigationandduedilligence/intransit",
        label: " ※ In-Transit",
      },
      {
        to: "/services/investigationandduedilligence/digital",
        label: " ※ Digital",
      },
      { to: "/services/investigationandduedilligence/aml", label: " ※ AML" },
      { to: "/services/investigationandduedilligence/kyc", label: " ※ KYC" },
      {
        to: "/services/investigationandduedilligence/marketresearch",
        label: " ※ Market Research",
      },
      {
        to: "/services/investigationandduedilligence/marketintelligence",
        label: " ※ Market Intelligence Gathering",
      },
      {
        to: "/services/investigationandduedilligence/cyberforensics",
        label: " ※ Cyber Forensics",
      },
      {
        to: "/services/investigationandduedilligence/informationsecurityandsystem",
        label: " ※ Information Security and Systems",
      },
      {
        to: "/services/investigationandduedilligence/sourceinvestigation",
        label: " ※ Source Investigations",
      },
    ],
  },
  {
    label: " ※ SERVICES FOR THE LEGAL INDUSTRY",
    content: [
      {
        to: "/services/serviceforlegalindustries/tminuse",
        label: " ※ TM In-Use",
      },
    ],
  },
  {
    label: " ※ OUR REPRESENTATIVE CLIENTS",
    content: [
      {
        to: "/partnersandrepresentativeclients/representativeclients/pharmacompanies",
        label: " ※ Pharma Companies",
      },
      {
        to: "/partnersandrepresentativeclients/representativeclients/fmcgcompanies",
        label: " ※ FMCG Companies",
      },
      {
        to: "/partnersandrepresentativeclients/representativeclients/hardwareandadhesive",
        label: " ※ Hardware & Adhesives",
      },
      {
        to: "/partnersandrepresentativeclients/representativeclients/automobiles",
        label: " ※ Automobiles",
      },
      {
        to: "/partnersandrepresentativeclients/representativeclients/electronics",
        label: " ※ Electronics/Mobiles",
      },
      {
        to: "/partnersandrepresentativeclients/representativeclients/agrichemical",
        label: " ※ Agri-Chemicals",
      },
      {
        to: "/partnersandrepresentativeclients/representativeclients/fashion",
        label: " ※ Fashion/Shoes/Apparel",
      },
      {
        to: "/partnersandrepresentativeclients/representativeclients/alcohol",
        label: " ※ Alcohol/Beverages",
      },
      {
        to: "/partnersandrepresentativeclients/representativeclients/consortiums",
        label: " ※ Consortiums",
      },
    ],
  },
];

function Navbar() {
  const [dropdownDisplay, setdropdownDisplay] = useState(false);
  const [dropdownContent, setDropdownContent] = useState(null);
  const [currentDd, setcurrentDd] = useState(0);
  const [currentServiceDd, setcurrentServiceDd] = useState(0);
  const [servicedropdownContent, setserviceDropdownContent] = useState(null);
  const [serviveddDisplay, setserviveddDisplay] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [user, setUser] = useState("Login");
  const token = localStorage.getItem("spanUserToken");
  const [isSearchBtnActive, setisSearchBtnActive] = useState(false);

  const [query, setquery] = useState("");
  const his = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    his(`/search/${query}`);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  const fetchData = useCallback(async () => {
    if (token && token !== "") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL
          }userdatafetch.php?token=${encodeURIComponent(token)}`
        );
        setUser(response.data.data.username);
      } catch (error) { }
    }
  }, [token]);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    fetchData();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchData, handleScroll]);

  //Dropdown Contents

  const PartnersDropdown = () => {
    return (
      <ul className="level1dd">
        <Link
          onClick={() => {
            setdropdownDisplay(false);
          }}
          to="/partnersandrepresentativeclients/partnership/partners"
        >
          Partners
          <i className="fa-solid fa-arrow-right-long"></i>
        </Link>
        <span
          onClick={() => {
            handleServiceNavHover(6);
            setserviveddDisplay(true);
          }}
        >
          Representative Clients
          <i className="fa-solid fa-arrow-right-long"></i>
        </span>
      </ul>
    );
  };

  const SpanSuitDropdown = () => {
    return (
      <ul className="level1dd">
        {dropdownData[0].content.map((content) => (
          <Link
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to={content.to}
          >
            {content.label}
            <i className="fa-solid fa-arrow-right-long"></i>
          </Link>
        ))}
      </ul>
    );
  };
  const IndustriesServedDropdown = () => {
    return (
      <ul className="level1dd">
        {dropdownData[2].content.map((content) => (
          <Link
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to={content.to}
          >
            {content.label}
            <i className="fa-solid fa-arrow-right-long"></i>
          </Link>
        ))}
      </ul>
    );
  };
  const ContactUsDropdown = () => {
    return (
      <ul className="level1dd">
        {dropdownData[5].content.map((content) => (
          <Link
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to={content.to}
          >
            {content.label}
            <i className="fa-solid fa-arrow-right-long"></i>
          </Link>
        ))}
      </ul>
    );
  };
  const ResourcesToolkitsDropdown = () => {
    return (
      <ul className="level1dd">
        {dropdownData[3].content.map((content) => (
          <Link
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to={content.to}
          >
            {content.label}
            <i className="fa-solid fa-arrow-right-long"></i>
          </Link>
        ))}
      </ul>
    );
  };
  const AboutUsDropdown = () => {
    return (
      <ul className="level1dd">
        {dropdownData[4].content.map((content) => (
          <Link
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to={content.to}
          >
            {content.label}
            <i className="fa-solid fa-arrow-right-long"></i>
          </Link>
        ))}
      </ul>
    );
  };
  const ServicesDropdown = () => {
    return (
      <ul className="level1dd">
        {dropdownData[1].content.map((content) => (
          <span
            onClick={() => {
              handleServiceNavHover(content.handler);
              setserviveddDisplay(true);
            }}
          >
            {content.label}
            <i className="fa-solid fa-arrow-right-long"></i>
          </span>
        ))}
      </ul>
    );
  };

  // Service SubSections

  const ServiceLegalBrandProtection = () => {
    return (
      <>
        <h3
          onClick={() => {
            setserviveddDisplay(false);
          }}
        >
          <i className="fa-solid fa-angle-left"></i>
          LEGAL BRAND PROTECTION
        </h3>

        {serviceDropdownData[0].content.map((content) => (
          <Link
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to={content.to}
          >
            {content.label}
          </Link>
        ))}
      </>
    );
  };
  const ServiceStrategicConsulting = () => {
    return (
      <>
        <h3
          onClick={() => {
            setserviveddDisplay(false);
          }}
        >
          <i className="fa-solid fa-angle-left"></i>
          STRATEGIC CONSULTING
        </h3>
        {serviceDropdownData[1].content.map((content) => (
          <Link
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to={content.to}
          >
            {content.label}
          </Link>
        ))}
      </>
    );
  };
  const ServicesInvestigationsDueDiligence = () => {
    return (
      <>
        <h3
          onClick={() => {
            setserviveddDisplay(false);
          }}
        >
          <i className="fa-solid fa-angle-left"></i>
          INVESTIGATIONS & DUE DILIGENCE
        </h3>
        {serviceDropdownData[2].content.map((content) => (
          <Link
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to={content.to}
          >
            {content.label}
          </Link>
        ))}
      </>
    );
  };
  const ServicesfirtheLegalIndustries = () => {
    return (
      <>
        <h3
          onClick={() => {
            setserviveddDisplay(false);
          }}
        >
          <i className="fa-solid fa-angle-left"></i>
          SERVICES FOR THE LEGAL INDUSTRY
        </h3>
        {serviceDropdownData[3].content.map((content) => (
          <Link
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to={content.to}
          >
            {content.label}
          </Link>
        ))}
      </>
    );
  };
  const RepresentativeClients = () => {
    return (
      <>
        <h3
          onClick={() => {
            setserviveddDisplay(false);
          }}
        >
          <i className="fa-solid fa-angle-left"></i>
          OUR REPRESENTATIVE CLIENTS
        </h3>
        {serviceDropdownData[4].content.map((content) => (
          <Link
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to={content.to}
          >
            {content.label}
          </Link>
        ))}
      </>
    );
  };

  // Level 1 Handing Function

  const handleNavHover = (index) => {
    // Set dropdown content based on the hovered index
    switch (index) {
      case 1:
        setDropdownContent(<SpanSuitDropdown />);
        setcurrentDd(1);
        setserviveddDisplay(false);
        setcurrentServiceDd(null);
        break;
      case 2:
        setDropdownContent(<ServicesDropdown />);
        setcurrentDd(2);
        setserviveddDisplay(false);
        setcurrentServiceDd(null);

        break;
      case 3:
        setDropdownContent(<IndustriesServedDropdown />);
        setcurrentDd(3);
        setserviveddDisplay(false);
        setcurrentServiceDd(null);

        break;
      case 4:
        setDropdownContent(<ResourcesToolkitsDropdown />);
        setcurrentDd(4);
        setserviveddDisplay(false);
        setcurrentServiceDd(null);

        break;
      case 5:
        setDropdownContent(<AboutUsDropdown />);
        setcurrentDd(5);
        setserviveddDisplay(false);
        setcurrentServiceDd(null);

        break;
      case 6:
        setDropdownContent(<ContactUsDropdown />);
        setcurrentDd(6);
        setserviveddDisplay(false);
        setcurrentServiceDd(null);

        break;
      case 7:
        setDropdownContent(<PartnersDropdown />);
        setcurrentDd(7);
        setserviveddDisplay(false);
        setcurrentServiceDd(null);

        break;
      default:
        setDropdownContent(null);
    }
  };

  // Level 2 Handing Function

  const handleServiceNavHover = (index) => {
    switch (index) {
      case 1:
        setserviceDropdownContent(<ServiceLegalBrandProtection />);
        setcurrentServiceDd(1);

        break;
      case 2:
        setserviceDropdownContent(<ServiceStrategicConsulting />);
        setcurrentServiceDd(2);

        break;
      case 3:
        setserviceDropdownContent(<ServicesInvestigationsDueDiligence />);
        setcurrentServiceDd(3);

        break;
      case 4:
        setserviceDropdownContent(
          <ServicesTraing
            setserviveddDisplay={setserviveddDisplay}
            setdropdownDisplay={setdropdownDisplay}
          />
        );
        setcurrentServiceDd(4);

        break;
      case 5:
        setserviceDropdownContent(<ServicesfirtheLegalIndustries />);
        setcurrentServiceDd(5);

        break;
      case 6:
        setserviceDropdownContent(<RepresentativeClients />);
        setcurrentServiceDd(6);

        break;
      default:
        setserviceDropdownContent(null);
    }
  };

  return (
    <>
      <nav
        style={
          isScrolled
            ? { backgroundColor: "white", color: "#da0e29" }
            : { backgroundColor: "#fff8e3", color: "black" }
        }
      >
        <Link to="/" id="nav-logo">
          <img src={logo} alt="" />
        </Link>
        {isSearchBtnActive ? (
          <></>
        ) : (
          <div id="main-links">
            <span
              style={
                currentDd === 1 && dropdownDisplay === true
                  ? { borderBottomColor: "#eb7c24" }
                  : {}
              }
              onClick={() => {
                handleNavHover(1);
                setdropdownDisplay(true);
                currentDd === 1 && dropdownDisplay === true
                  ? setdropdownDisplay(false)
                  : setdropdownDisplay(true);
              }}
            >
              SPAN Suite
            </span>
            <span
              style={
                currentDd === 2 && dropdownDisplay === true
                  ? { borderBottomColor: "#eb7c24" }
                  : {}
              }
              onClick={() => {
                handleNavHover(2);
                setdropdownDisplay(true);
                currentDd === 2 && dropdownDisplay === true
                  ? setdropdownDisplay(false)
                  : setdropdownDisplay(true);
              }}
            >
              Services
            </span>
            <span
              style={
                currentDd === 7 && dropdownDisplay === true
                  ? { borderBottomColor: "#eb7c24" }
                  : {}
              }
              onClick={() => {
                handleNavHover(7);
                setdropdownDisplay(true);
                currentDd === 7 && dropdownDisplay === true
                  ? setdropdownDisplay(false)
                  : setdropdownDisplay(true);
              }}
            >
              Partners
            </span>

            {/* <Link
            style={isScrolled ? { color: "#da0e29" } : { color: "black" }}
            onClick={() => {
              setdropdownDisplay(false);
            }}
            to="/partnership"
          >
            Partners
          </Link> */}
            <span
              style={
                currentDd === 3 && dropdownDisplay === true
                  ? { borderBottomColor: "#eb7c24" }
                  : {}
              }
              onClick={() => {
                handleNavHover(3);
                setdropdownDisplay(true);
                currentDd === 3 && dropdownDisplay === true
                  ? setdropdownDisplay(false)
                  : setdropdownDisplay(true);
              }}
            >
              Industries
            </span>
            <span
              style={
                currentDd === 4 && dropdownDisplay === true
                  ? { borderBottomColor: "#eb7c24" }
                  : {}
              }
              onClick={() => {
                handleNavHover(4);
                setdropdownDisplay(true);
                currentDd === 4 && dropdownDisplay === true
                  ? setdropdownDisplay(false)
                  : setdropdownDisplay(true);
              }}
            >
              Resources and Toolkits
            </span>
            <span
              style={
                currentDd === 5 && dropdownDisplay === true
                  ? { borderBottomColor: "#eb7c24" }
                  : {}
              }
              onClick={() => {
                handleNavHover(5);
                setdropdownDisplay(true);
                currentDd === 5 && dropdownDisplay === true
                  ? setdropdownDisplay(false)
                  : setdropdownDisplay(true);
              }}
            >
              About Us
            </span>
            <span
              style={
                currentDd === 6 && dropdownDisplay === true
                  ? { borderBottomColor: "#eb7c24" }
                  : {}
              }
              onClick={() => {
                handleNavHover(6);
                setdropdownDisplay(true);
                currentDd === 6 && dropdownDisplay === true
                  ? setdropdownDisplay(false)
                  : setdropdownDisplay(true);
              }}
            >
              Contact Us
            </span>
          </div>
        )}
        <div id="other-links">
          {isSearchBtnActive ? (
            <form
              className="searchnavbar-form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <label htmlFor="search">
                <input
                  className="searchnavbar-input"
                  type="text"
                  required=""
                  placeholder="Search"
                  id="search"
                  value={query}
                  onChange={(e) => {
                    setquery(e.target.value);
                  }}
                />
                <div className="searchnavbar-fancy-bg" />
                <div className="searchnavbar-search">
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    className="searchnavbar-icon"
                  >
                    <g>
                      <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
                    </g>
                  </svg>
                </div>
                <button
                  className="searchnavbar-close-btn"
                  type="reset"
                  onClick={() => {
                    setisSearchBtnActive(!isSearchBtnActive);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="searchnavbar-close-icon"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </label>
            </form>
          ) : (
            <Link
              onClick={() => {
                setisSearchBtnActive(!isSearchBtnActive);
              }}
              style={isScrolled ? { color: "#da0e29" } : { color: "black" }}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
              <p> Search </p>
            </Link>
          )}
          <Link
            style={isScrolled ? { color: "#da0e29" } : { color: "black" }}
            to="/login"
          >
            <i className="fa-solid fa-user"></i>
            <p> {user} </p>
          </Link>
        </div>
      </nav>
      <div
        id="dropdown"
        style={{
          top: `${dropdownDisplay ? "10vh" : "-100vh"}`,
          minHeight: `${currentServiceDd === 3 || currentServiceDd === 4
              ? "500px"
              : currentDd === 2 || currentServiceDd === 6
                ? "50vh"
                : "fit-content"
            }`,
        }}
      >
        {dropdownContent}
        <ul
          className="level2dd"
          style={
            serviveddDisplay
              ? { left: "67%", visibility: "visible" }
              : { left: "33%", visibility: "hidden" }
          }
        >
          {servicedropdownContent}
        </ul>
        <div
          id="empty-div"
          onClick={() => {
            setdropdownDisplay(false);
          }}
        ></div>
      </div>
    </>
  );
}

export default Navbar;
