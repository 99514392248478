import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import validator from "validator";

function NotAnUser() {
  const loc = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phono, setPhono] = useState("");
  const [isRegisteredEmail, setIsRegisteredEmail] = useState(false);

  // Function to validate email format
  const isEmailValid = (email) => {
    return validator.isEmail(email);
  };

  // Function to validate phone number format
  const isPhoneValid = (phone) => {
    return validator.isMobilePhone(phone, "any"); // You can specify a locale if needed
  };

  const registerEmail = async () => {
    try {
      if (!name) {
        alert("Name cannot be empty");
        return;
      }
      if (!isEmailValid(email)) {
        alert("Invalid email format");
        return;
      }
      if (!isPhoneValid(phono)) {
        alert("Invalid phone number format");
        return;
      }
      await axios.get(
        `${process.env.REACT_APP_API_URL}addemail.php?email=${email}&name=${name}&mobile=${phono}`
      );
      localStorage.setItem("RegisteredEmail", email);
      window.alert("Email Registered!!");
      setIsRegisteredEmail(true);
    } catch (error) {
      alert("Something Went Wrong!!");
      console.error("Error registering email:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("RegisteredEmail");
    setIsRegisteredEmail(token && token !== "");
  }, []);

  return (
    <div
      id="NotAnUser"
      style={{
        display:
          isRegisteredEmail ||
            loc.pathname === "/" ||
            loc.pathname.includes("admin") ||
            loc.pathname === "/signin" ||
            loc.pathname === "/contactus" ||
            loc.pathname === "/login"
            ? "none"
            : "flex",
      }}
    >
      <div className="notuser-card">
        <div className="notuser-header">
          <div className="notuser-content">
            <span className="notuser-title">Sensitive Content</span>
            <p className="notuser-message">
              You need to be our user to discover our Content
            </p>
          </div>
          <div className="notuser-actions">
            <button className="notuser-cancel" type="button">
              <input
                type="text"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </button>
            <button className="notuser-cancel" type="button">
              <input
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </button>
            <button className="notuser-cancel" type="button">
              <input
                type="text"
                placeholder="Enter Phone No"
                value={phono}
                onChange={(e) => setPhono(e.target.value)}
              />
            </button>
            <button
              className="notuser-desactivate"
              type="button"
              onClick={registerEmail}
            >
              Register Email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotAnUser;
