import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "./Loader";

const roles = {
  1: "Brand Protection Specialist",
  2: "Manager, Brand Protection",
  3: "Legal Associate",
};

function CareerApplication() {
  const { applicationId } = useParams();
  const [applicationFor, setApplicationFor] = useState("");
  const [formData, setFormData] = useState({
    jobRole: applicationId,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    education: "",
    experience: "",
    skills: "",
    resume: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");

  useEffect(() => {
    setApplicationFor(roles[applicationId]);
  }, [applicationId]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "resume") {
      setFormData((prevData) => ({ ...prevData, resume: files[0] }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Validate file size and type
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      const { resume } = formData;

      if (
        !resume ||
        resume.size > 2 * 1024 * 1024 ||
        !validTypes.includes(resume.type)
      ) {
        alert("Please upload a valid image file (JPEG, PNG) under 2MB.");
        setIsSubmitting(false);
        return;
      }

      // Rename the file
      const timestamp = Date.now();
      const newFileName = `resume-${formData.email}-${timestamp}.${resume.name
        .split(".")
        .pop()}`;
      const renamedFile = new File([resume], newFileName, {
        type: resume.type,
      });

      // Upload the file
      const uploadData = new FormData();
      uploadData.append("fileToUpload", renamedFile);

      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}ftp.php`,
        uploadData
      );

      if (uploadResponse.data.status === "success") {
        const filePath = uploadResponse.data.filePath;

        // Send email to the user
        try {
          const userSubject = "Thank you for your application";
          const userBody = `
            <h1>Thank you for applying for the ${applicationFor} position</h1>
            <p>We have received your application and our team will review it. You will be contacted if you are shortlisted.</p>
            <p>Best regards,<br>The Recruitment Team</p>
          `;
          await axios.post(process.env.REACT_APP_MAIL_API, {
            receiverMail: formData.email,
            subject: userSubject,
            body: userBody,
          });
        } catch (error) {
          console.error("Failed to send email to user:", error);
        }

        // Send email to the admin
        try {
          const adminSubject = `New Application for ${applicationFor}`;
          const adminBody = `
            <h1>New Application Received</h1>
            <p><strong>First Name:</strong> ${formData.firstName}</p>
            <p><strong>Last Name:</strong> ${formData.lastName}</p>
            <p><strong>Email:</strong> ${formData.email}</p>
            <p><strong>Phone Number:</strong> ${formData.phoneNumber}</p>
            <p><strong>City:</strong> ${formData.city}</p>
            <p><strong>State:</strong> ${formData.state}</p>
            <p><strong>Country:</strong> ${formData.country}</p>
            <p><strong>Education:</strong> ${formData.education}</p>
            <p><strong>Skills:</strong> ${formData.skills}</p>
            <p><strong>Experience:</strong> ${formData.experience}</p>
            <p><strong>Job Role:</strong> ${applicationFor}</p>
          `;
          await axios.post(process.env.REACT_APP_MAIL_API, {
            receiverMail: process.env.REACT_APP_ADMIN_EMAIL,
            subject: adminSubject,
            body: adminBody,
            attachments: [
              {
                filename: renamedFile.name,
                path: "https://spanip.com/spann/" + filePath,
              },
            ],
          });
        } catch (error) {
          console.error("Failed to send email to admin:", error);
        }

        // Reset form and show success message
        setIsSubmitting(false);
        setSubmissionMessage("Application submitted successfully!");
        alert("Application submitted successfully!");
        setFormData({
          jobRole: applicationId,
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          city: "",
          state: "",
          zipCode: "",
          country: "",
          education: "",
          experience: "",
          skills: "",
          resume: null,
        });
      } else {
        throw new Error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitting(false);
      setSubmissionMessage(
        "An error occurred while submitting the application."
      );
    }
  };

  return (
    <div id="Enquiry">
      {isSubmitting && <Loader />}
      <h1>Application for {applicationFor}</h1>
      <form className="form" onSubmit={handleSubmit}>
        {renderInput(
          "firstName",
          "First Name",
          "text",
          formData,
          handleChange,
          true
        )}
        {renderInput("lastName", "Last Name", "text", formData, handleChange)}
        {renderInput("email", "Email", "email", formData, handleChange, true)}
        {renderInput(
          "phoneNumber",
          "Phone Number",
          "tel",
          formData,
          handleChange,
          true
        )}
        {renderInput("city", "City", "text", formData, handleChange, true)}
        {renderInput("state", "State", "text", formData, handleChange)}
        {renderInput(
          "country",
          "Country",
          "text",
          formData,
          handleChange,
          true
        )}
        {renderInput(
          "zipCode",
          "Pin Code",
          "number",
          formData,
          handleChange,
          true
        )}

        <div id="message" style={{ width: "100%" }}>
          {renderTextarea("education", "Education", formData, handleChange)}
          {renderTextarea("skills", "Skills", formData, handleChange)}
          {renderTextarea("experience", "Experience", formData, handleChange)}
          <label htmlFor="resume">
            Upload Resume/CV (Upload in png/jpg/jpeg only)
          </label>
          <input
            type="file"
            id="resume"
            name="resume"
            onChange={handleChange}
            required
          />
          <p>
            By submitting this form, you agree to our Privacy Policy and Terms
            of Service.
          </p>
        </div>
        <button type="submit">Submit</button>
      </form>
      {submissionMessage && <p>{submissionMessage}</p>}
    </div>
  );
}

function renderInput(
  name,
  label,
  type,
  formData,
  handleChange,
  required = false
) {
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        id={name}
        name={name}
        value={formData[name]}
        onChange={handleChange}
        required={required}
      />
    </div>
  );
}

function renderTextarea(name, label, formData, handleChange) {
  return (
    <>
      <label htmlFor={name}>{label}</label>
      <textarea
        id={name}
        name={name}
        cols="30"
        rows="10"
        value={formData[name]}
        onChange={handleChange}
      />
    </>
  );
}

export default CareerApplication;
