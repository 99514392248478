import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import CookiePolicy from "./Components/CokieePolicy";
import LandingPage from "./Components/Home/LandingPage";
import Contact from "./Components/Contact";
import Enquiry from "./Components/Enquiry";
import "./Components/style.css";
import "./Components/phone-style.css";
import Disclamer from "./Components/Disclamer";
import ServiceTemplate from "./Components/Templates/ServiceTemplate";
import AboutUsTemplate from "./Components/Templates/AboutUsTemplate";
import SpanSuit from "./Components/Templates/SpanTemplate";
import Industries from "./Components/Templates/Industries";
import Resources from "./Components/Templates/Resources";
import TraningTemplate from "./Components/Templates/Traning";
import Message from "./Components/Message";
import MobileNavbar from "./Components/MobileNavbar";
import Partnership from "./Components/Partnership";
import SignIn from "./DashBoards/SignIn";
import Login from "./DashBoards/Login";
import User from "./DashBoards/User";
import Programme from "./Components/Programme";
import SpanBrandProtection from "./Components/SpanBrandProtection";
import BrandPrtForm1 from "./Components/BrandPrtForm1";
import BrandPrtForm2 from "./Components/BrandPrtForm2";
import Settings from "./DashBoards/Sections/Settings";
import CheckMyfiles from "./DashBoards/Sections/CheckMyfiles";
import ContactUs from "./DashBoards/Sections/ContactUs";
import Status from "./DashBoards/Sections/Status";
// import AllUser from "./Admin/AllUser";
import AdminViewUser from "./Admin/AdminUserView";
import CasesList from "./DashBoards/Sections/CasesList";
import AdminCaseList from "./Admin/Sections/AdminCaseList";
import AdminStatusView from "./Admin/Sections/AdminStatusView";
import AdminFilesView from "./Admin/Sections/AdminFilesView";
import AdminContactUs from "./Admin/Sections/AdminContactUs";
import AdminLogin from "./Admin/AdminLogin";
import NotAnUser from "./Components/NotAnUser";
import Search from "./Components/Results";
import Blogs from "./Components/Blogs/Blogs.jsx";
import Blog from "./Components/Blogs/Blog";
import Carrers from "./Components/Carrers.jsx";
import CarrerApplication from "./Components/CarrerApplication.jsx";
import AdminDashboard from "./Admin/AdminDashboard.jsx";
import UserList from "./Admin/UserList.jsx";
import AdminList from "./Admin/AdminList.jsx";
import AdminMedia from "./Admin/AdminMedia.jsx";
import Refund from "./Components/RefundPolicy.jsx";
import Terms from "./Components/Terms.jsx";

function App() {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showCookiePolicy, setShowCookiePolicy] = useState(false);

  useEffect(() => {
    const disclaimerShown = localStorage.getItem("disclaimerShown");
    console.log("Disclaimer shown:", disclaimerShown);

    if (!disclaimerShown) {
      setShowDisclaimer(true);
      localStorage.setItem("disclaimerShown", "true");
    }

    const cookiePolicyAccepted = localStorage.getItem("cookiePolicyAccepted");
    console.log("Cookie policy accepted:", cookiePolicyAccepted);

    if (!cookiePolicyAccepted && disclaimerShown === "true") {
      setTimeout(() => {
        setShowCookiePolicy(true);
      }, 5000);
    }
  }, []);

  const acceptCookiePolicy = () => {
    localStorage.setItem("cookiePolicyAccepted", "true");
    setShowCookiePolicy(false);
  };

  return (
    <Router>
      {showDisclaimer && (
        <Disclamer
          showDisclaimer={showDisclaimer}
          setShowDisclaimer={setShowDisclaimer}
        />
      )}
      {showCookiePolicy && (
        <CookiePolicy acceptCookiePolicy={acceptCookiePolicy} />
      )}
      <Navbar />
      <MobileNavbar />
      <NotAnUser />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/partnersandrepresentativeclients/:section/:subsection"
          element={<Partnership />}
        />
        <Route path="/about/directorsmessage" element={<Message />} />
        <Route path="/spansuit/:section" element={<SpanSuit />} />
        <Route
          path="/spansuit/brandprotection"
          element={<SpanBrandProtection />}
        />
        <Route
          path="/spansuit/brandprotection/form1"
          element={<BrandPrtForm1 />}
        />
        <Route
          path="/spansuit/brandprotection/form2"
          element={<BrandPrtForm2 />}
        />
        <Route path="/search/:key" element={<Search />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/inquiry" element={<Enquiry />} />
        <Route path="/aboutus/careers" element={<Carrers />} />
        <Route
          path="/carrer/apply/:applicationId"
          element={<CarrerApplication />}
        />
        <Route path="/aboutus/:section" element={<AboutUsTemplate />} />
        <Route path="/traning/:section" element={<TraningTemplate />} />
        <Route path="/resources/blogs" element={<Blogs />} />
        <Route path="/resources/blog/:section" element={<Blog />} />
        <Route path="/resources/:section" element={<Resources />} />
        <Route path="/industries/:section" element={<Industries />} />
        <Route
          path="/services/:section/:subsection"
          element={<ServiceTemplate />}
        />
        <Route
          exact
          path="/services/traning/healthcareinstitutions/programme"
          element={<Programme />}
        />
        <Route
          path="/services/:section/:tempsection/:subsection"
          element={<ServiceTemplate />}
        />
        <Route path="/aboutus/refundPolicy" element={<Refund />} />
        <Route path="/aboutus/termsandConditions" element={<Terms />} />
        {/* DashBoards Routes */}

        <Route path="/login" element={<Login />} />
        <Route path="/user" element={<User />}>
          <Route path="status" element={<CasesList />} />
          <Route path="status/:caseno" element={<Status />} />
          <Route path="settings" element={<Settings />} />
          <Route path="checkmyfiles" element={<CasesList />} />
          <Route path="checkmyfiles/:caseno" element={<CheckMyfiles />} />
          
        </Route>

        {/* Admin Routes */}
        <Route path="/admin" element={<AdminDashboard />}>
          <Route path="users" element={<UserList />} />
          <Route path="admins" element={<AdminList />} />
          <Route path="media" element={<AdminMedia />} />
        </Route>
        <Route path="/login/admin" element={<AdminLogin />} />
        <Route path="/admin/createuser" element={<SignIn />} />
        <Route path="/admin/user/:username" element={<AdminViewUser />}>
          <Route path="status" element={<AdminCaseList />} />
          <Route path="status/:caseno" element={<AdminStatusView />} />
          <Route path="checkmyfiles" element={<AdminCaseList />} />
          <Route path="checkmyfiles/:caseno" element={<AdminFilesView />} />
          <Route path="contactus" element={<AdminContactUs />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
