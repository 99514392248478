import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function Carrers() {
  const jobOpenings = [
    {
      id: 1,
      title: "Brand Protection Specialist",
      company: "SPAN Consulting",
      location: "Delhi and Mumbai",
      description: `SPAN Consulting is a distinguished consulting and brand protection firm specializing in the meticulous management of intellectual property rights (IPR). 
      With a steadfast commitment to excellence, we deliver tailored solutions to safeguard our clients' brands, innovations, and creative works from unauthorized use and infringement. 
      Our esteemed team comprises legal experts, investigators, and consultants who exemplify proficiency and dedication in ensuring the protection of our clients' intellectual property assets.`,
      responsibilities: [
        "Conduct thorough investigations to identify instances of brand infringement, counterfeiting, and unauthorized use of intellectual property assets.",
        "Develop and implement proactive enforcement strategies to mitigate risks and combat various forms of intellectual property infringement.",
        "Collaborate with internal and external stakeholders to foster partnerships and align brand protection objectives.",
        "Monitor brand usage across online and offline channels to detect and address unauthorized distribution and sale of counterfeit goods.",
        "Provide educational support to clients and stakeholders on the importance of brand protection and intellectual property rights.",
        "Maintain accurate records of investigations and produce detailed reports for clients and internal use.",
      ],
      qualifications: [
        "Bachelor's degree in law, Business, Criminology, or related field.",
        "Proven experience in brand protection or intellectual property enforcement.",
        "Strong understanding of intellectual property laws, regulations, and enforcement mechanisms.",
        "Excellent analytical, communication, and negotiation skills.",
        "Proficiency in data entry and report writing.",
        "Ability to work effectively both independently and collaboratively in a fast-paced environment.",
      ],
    },
    {
      id: 2,
      title: "Manager, Brand Protection",
      company: "SPAN Consulting",
      location: "India & South Asia, APAC Region",
      description: `The Manager, Brand Protection and Investigations, reports to the Director, Global Brand Protection (GBP) and is primarily responsible for assessing, developing, and implementing processes, programs, and policies that help protect our consumers, patients, business, and the reputation of our Clients from various forms of illicit trade including counterfeiting, illegal diversion, and tampering across the India & South Asia cluster of APAC Region.`,
      responsibilities: [
        "Leads and manages a team’s performance, direction, hiring, retention, training/development.",
        "Case management responsibility in guiding Investigations related to counterfeiting, diversion, product tampering, theft, and threats.",
        "Provide regular case evaluations including monetary value and cost avoidance results.",
        "Manage a team of Product Security Investigations and Enforcement (PSI&E) Team to optimize the effectiveness and efficiencies of day-to-day activities.",
        "Deploying critical thinking skillsets to establish new concepts and solutions to enhance Investigative and Brand Protection strategies to proactively combat various security threats.",
        "Guide and lead virtual and in-person physical security audits to mitigate risks.",
        "Build key relationships both internally and externally.",
      ],
      qualifications: [
        "A minimum of a bachelor's degree.",
        "Ex Defence Forces is preferred.",
        "A minimum of 5-8 years of relevant technical and business experience including experience in a supervisory role.",
        "Holds themselves to the highest ethical standards and maintains compliance with the Company's Code of Business Conduct.",
        "Must be able to understand, respect, and work within the cultural diversity of the global environment.",
        "Highly proficient communication skills, written and oral.",
        "Business fluency in English with at least one additional regional language.",
      ],
    },
    {
      id: 3,
      title: "Legal Associate",
      company: "SPAN Consulting",
      location: "Delhi and Mumbai",
      description: `SPAN Consulting stands as a distinguished consultancy and brand protection enterprise, specializing in the meticulous management of intellectual property rights (IPR). 
      With an unyielding dedication to excellence, we offer tailored solutions to shield our clients' brands, innovations, and creative endeavors from unauthorized use and infringement. 
      Our esteemed team comprises legal experts, investigators, and consultants who embody proficiency and unwavering commitment to ensuring the safeguarding of our clients' intellectual property assets.`,
      responsibilities: [
        "Conduct thorough legal research and analysis on intellectual property laws, regulations, and case precedents.",
        "Assist in the preparation and filing of trademark, copyright, and patent applications, as well as responses to office actions.",
        "Draft and review various legal documents, including contracts, cease and desist letters, and settlement agreements pertaining to intellectual property matters.",
        "Provide legal advice and counsel to clients on strategies for intellectual property protection, infringement issues, and enforcement actions.",
        "Assist in intellectual property litigation proceedings, including discovery, motion practice, and trial preparation.",
        "Collaborate effectively with internal teams and external stakeholders, such as clients, government agencies, and industry organizations, to coordinate intellectual property enforcement efforts.",
        "Stay abreast of emerging trends and developments in intellectual property law and provide timely advice to clients.",
        "Contribute to client education and training programs on intellectual property rights and best practices for brand protection.",
      ],
      qualifications: [
        "Bachelor's degree in Law from an accredited institution; admission to the Bar in relevant jurisdiction is mandatory.",
        "Possess 1-3 years of experience practicing intellectual property law, preferably within a law firm or corporate legal department.",
        "Demonstrated proficiency in understanding trademark, copyright, and patent laws, with experience in intellectual property enforcement and litigation.",
        "Exceptional legal research, writing, and analytical skills are essential.",
        "Ability to effectively communicate complex legal concepts to clients and colleagues.",
        "Meticulous attention to detail coupled with strong organizational and time management abilities.",
        "Capable of working both independently and collaboratively in a fast-paced environment.",
        "Proficiency in legal research databases and software applications is advantageous.",
      ],
    },
  ];
  const observedElements = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.transform = "scale(1)";
            entry.target.style.opacity = "1";
          } else {
            entry.target.style.transform = "scale(0.95)";
            entry.target.style.opacity = "0";
          }
        });
      },
      { threshold: 0.1 }
    );

    observedElements.current.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const oneApplicationCard = (application) => {
    return (
      <div className="application-card">
        <h1
          ref={(el) => {
            if (el) {
              observedElements.current.push(el);
            }
          }}
        >
          {application.title}
        </h1>
        <p
          ref={(el) => {
            if (el) {
              observedElements.current.push(el);
            }
          }}
        >
          <strong>Location:</strong> {application.location}
        </p>
        <p
          ref={(el) => {
            if (el) {
              observedElements.current.push(el);
            }
          }}
        >
          {application.description}
        </p>
        <h4
          ref={(el) => {
            if (el) {
              observedElements.current.push(el);
            }
          }}
        >
          Responsibilities
        </h4>
        <ul
          ref={(el) => {
            if (el) {
              observedElements.current.push(el);
            }
          }}
        >
          {application.responsibilities.map((responsibility) => (
            <li
              ref={(el) => {
                if (el) {
                  observedElements.current.push(el);
                }
              }}
              key={responsibility}
            >
              {responsibility}
            </li>
          ))}
        </ul>
        <h4
          ref={(el) => {
            if (el) {
              observedElements.current.push(el);
            }
          }}
        >
          Qualifications
        </h4>
        <ul
          ref={(el) => {
            if (el) {
              observedElements.current.push(el);
            }
          }}
        >
          {application.qualifications.map((qualification) => (
            <li
              ref={(el) => {
                if (el) {
                  observedElements.current.push(el);
                }
              }}
              key={qualification}
            >
              {qualification}
            </li>
          ))}
        </ul>
        <Link
          to={`/carrer/apply/${application.id}`}
          ref={(el) => {
            if (el) {
              observedElements.current.push(el);
            }
          }}
        >
          Apply Now
        </Link>
      </div>
    );
  };
  return (
    <div className="template">
      <div className="templatebrd aboutusbread">
        <h2>ABOUT US / CAREERS</h2>
        <h1>CAREERS</h1>
      </div>
      <div className="templatecontent">
        <section>
          <h2
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            WE FIND YOU
          </h2>
          <p
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            You want to accompany us
          </p>
          <p
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            We are looking for smart individuals who want to have a positive
            impact on the world. If that’s you, visit our Careers page or send
            us a CV at: <a href="mailto:admin@spanip.com">admin@spanip.com</a>
          </p>
        </section>
        <section>
          <h2
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            Join Our Team
          </h2>
          <p
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            At SPAN Consulting, we are on the lookout for talented individuals
            who are passionate about making a difference. If you are driven,
            innovative, and ready to be part of a team that values excellence
            and dedication, we invite you to explore our current job openings
            and apply. Send your CV to:{" "}
            <a
              ref={(el) => {
                if (el) {
                  observedElements.current.push(el);
                }
              }}
              href="mailto:admin@spanip.com"
            >
              admin@spanip.com
            </a>
          </p>
        </section>
        <section>
          <h2
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            Professional Development and Growth
          </h2>
          <p
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            At SPAN Consulting, we believe in continuous learning and growth. We
            offer our employees numerous opportunities for professional
            development, including regular training sessions, workshops, and
            access to industry-leading resources.
          </p>
          <blockquote
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            “Investing in our employees' growth not only enhances their skills
            but also drives the success of our company. We are committed to
            providing a supportive environment where everyone can thrive,” says{" "}
            <strong>
              PANKAJ MONGA, SPAN CONSULTING {"(Founder & Director)"}{" "}
            </strong>
            .
          </blockquote>
          <p
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            Our commitment to professional development ensures that our team
            remains at the forefront of industry trends and best practices.
          </p>
        </section>
        <section>
          <h2
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            Work-Life Balance
          </h2>
          <p
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            SPAN Consulting values the well-being of its employees. We offer
            flexible working hours, remote work options, and comprehensive
            health and wellness programs to ensure our team members maintain a
            healthy work-life balance.
          </p>
          <blockquote
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            “A balanced work-life dynamic is crucial for creativity and
            productivity. We strive to create an environment where our employees
            can excel professionally while enjoying personal fulfillment,” says{" "}
            <strong>PANKAJ MONGA</strong>.
          </blockquote>
          <p
            ref={(el) => {
              if (el) {
                observedElements.current.push(el);
              }
            }}
          >
            Our policies are designed to support our employees in achieving a
            harmonious balance between their work and personal lives.
          </p>
        </section>
        {jobOpenings.map((job) => {
          return oneApplicationCard(job);
        })}
      </div>
    </div>
  );
}

export default Carrers;
