import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";

function AdminMedia() {
  const [isAddStepActive, setIsAddStepActive] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  const fetchAdmin = async () => {
    try {
      const token = localStorage.getItem("spanAdminToken");
      if (!token) {
        navigate("/login/admin");
        return;
      }
      await axios.get(
        `${process.env.REACT_APP_API_URL}adminfetch.php?token=${token}`
      );
    } catch (error) {
      navigate("/login/admin");
      console.log(error);
    }
  };

  // Uncomment if you need to fetch admin on component mount
  // useEffect(() => {
  //   fetchAdmin();
  // }, []);

  const AddBlog = () => {
    const [formData, setFormData] = useState({
      title: "",
      author: "",
      date: "",
      tag: "",
      content: "",
      fileToUpload: null,
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const handleFileChange = (e) => {
      setFormData({
        ...formData,
        fileToUpload: e.target.files[0],
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      setisLoading(true);

      try {
        const uploadData = new FormData();
        uploadData.append("fileToUpload", formData.fileToUpload);

        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}ftp.php`,
          uploadData
        );

        if (uploadResponse.data.status === "success") {
          const blogData = new FormData();
          blogData.append("title", formData.title);
          blogData.append("author", formData.author);
          blogData.append("date", formData.date);
          blogData.append("tag", formData.tag);
          blogData.append("content", formData.content);
          blogData.append("img", uploadResponse.data.filePath);

          const blogResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}addblog.php`,
            blogData
          );

          if (blogResponse.data.status === "success") {
            alert("Blog post added successfully!");
          } else {
            alert("Error adding blog post: " + blogResponse.data.message);
          }
        } else {
          alert("File upload failed: " + uploadResponse.data.message);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setisLoading(false);
      }
    };

    return (
      <div id="CreateCase">
        <form autoComplete="off" id="StepCreateForm" onSubmit={handleSubmit}>
          <span
            onClick={() => setIsAddStepActive(!isAddStepActive)}
            id="crossbtn"
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
          <div>
            <label>Title:</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Author:</label>
            <input
              type="text"
              name="author"
              value={formData.author}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Date:</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Tag:</label>
            <input
              type="text"
              name="tag"
              value={formData.tag}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Content:</label>
            <textarea
              name="content"
              value={formData.content}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div>
            <label>Select image to upload:</label>
            <input
              type="file"
              name="fileToUpload"
              onChange={handleFileChange}
              required
            />
          </div>
          <input type="submit" value="Upload and Submit" />
        </form>
      </div>
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      {isAddStepActive && <AddBlog />}
      <div>
        AdminMedia
        <div className="filesoptions">
          <Link
            onClick={() => {
              setIsAddStepActive(true);
            }}
          >
            <button style={{ backgroundColor: "#58e04c" }}>
              <i className="fa-solid fa-plus"></i> Add Blog
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default AdminMedia;
