import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function AdminList() {
  const [admins, setAdmins] = useState([]);
  const [isAddStepActive, setIsAddStepActive] = useState(false);
  const [isChangePasswordActive, setIsChangePasswordActive] = useState(false);
  const [myAccount, setMyAccount] = useState({});
  const navigate = useNavigate();

  const adminUrl = `${process.env.REACT_APP_API_URL}fetchadmins.php`;

  const fetchAdmin = async () => {
    try {
      const token = localStorage.getItem("spanAdminToken");
      if (!token) {
        navigate("/login/admin");
        return;
      }
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}adminfetch.php?token=${token}`
      );
      setMyAccount(res.data.data);
    } catch (error) {
      navigate("/login/admin");
      console.log(error);
    }
    try {
      const adminList = await axios.get(adminUrl);
      setAdmins(adminList.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  const AddAdmin = () => {
    const [formData, setFormData] = useState({
      Name: "",
      Username: "",
      Password: "",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.get(
          `${process.env.REACT_APP_API_URL}admincreate.php?name=${formData.Name}&username=${formData.Username}&password=${formData.Password}&token=2a8e5b0d8f97a6c44b516345b20f83e8`
        );
        window.alert(
          `Admin Created Successfully, please refresh to see added admin`
        );
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <div id="CreateCase">
        <form autoComplete="off" id="StepCreateForm" onSubmit={handleSubmit}>
          <span
            onClick={() => setIsAddStepActive(!isAddStepActive)}
            id="crossbtn"
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
          <div>
            <label>Name</label>
            <input
              type="text"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Username</label>
            <input
              type="text"
              name="Username"
              value={formData.Username}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="text"
              name="Password"
              value={formData.Password}
              onChange={handleChange}
            />
          </div>
          <input type="submit" value={"Submit"} />
        </form>
      </div>
    );
  };

  const ChangePassword = () => {
    const [password, setPassword] = useState("");

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.get(
          `${process.env.REACT_APP_API_URL}updateadminpassword.php?username=${myAccount.Username}&password=${password}`
        );
        window.alert("Password Changed Successfully");
        setIsChangePasswordActive(false);
        setPassword("");
      } catch (error) {
        window.alert("Password Not Changed, Please try again");
      }
    };

    return (
      <div id="CreateCase">
        <form autoComplete="off" id="StepCreateForm" onSubmit={handleSubmit}>
          <span
            onClick={() => setIsChangePasswordActive(!isChangePasswordActive)}
            id="crossbtn"
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
          <div>
            <label>Set New Password:</label>
            <input
              type="text"
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <input type="submit" value={"Submit"} />
        </form>
      </div>
    );
  };

  return (
    <div id="AdminAllUser">
      {myAccount.Username === "admin" ? (
        <div className="filesoptions">
          <button
            style={{ backgroundColor: "#58e04c" }}
            onClick={() => setIsAddStepActive(true)}
          >
            <i className="fa-solid fa-plus"></i> Add Admin
          </button>
          <button
            style={{ backgroundColor: "#58e04c" }}
            onClick={() => setIsChangePasswordActive(true)}
          >
            <i className="fa-solid fa-key"></i> Change Password
          </button>
        </div>
      ) : (
        <div className="filesoptions">
          <button
            style={{ backgroundColor: "#58e04c" }}
            onClick={() => setIsChangePasswordActive(true)}
          >
            <i className="fa-solid fa-key"></i> Change Password
          </button>
        </div>
      )}
      {isAddStepActive ? <AddAdmin /> : null}
      {isChangePasswordActive ? <ChangePassword /> : null}
      <div id="UserList">
        {admins.map((admin) => (
          <div key={admin.id} className="admin-user-card">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/profil_photo.webp"}
              alt=""
              className="admin-user-card-image"
            />
            <div className="admin-user-info">
              <div>
                <span className="admin-user-label">Username:</span>{" "}
                {decodeURIComponent(admin.Username)}
              </div>
              <div>
                <span className="admin-user-label">Password:</span>{" "}
                {decodeURIComponent(admin.Password)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminList;
